

const HeaderScripts = () => {


    const setTopContentOffset = () => {
        const headerHeight = document.querySelector('.header').clientHeight;
        document.querySelector('.main').style.paddingTop = headerHeight + 'px';
    }
    setTopContentOffset();
    window.addEventListener('resize', setTopContentOffset);

    document.querySelector('#searchbar-trigger').addEventListener('click', function (event) {
        document.getElementById('desktop-search-bar').classList.toggle('opacity-0');
        document.querySelector('#desktop-search-bar .form-control').focus();
    });

    var burgerMenu = document.getElementById('burger-menu');
    var overlay = document.querySelector('.navbar-nav');
    burgerMenu.addEventListener('click',function(){
        this.classList.toggle("close");
        overlay.classList.toggle("opened");
    });

    document.querySelectorAll('.nav-item').forEach( (menuitem) => {
        menuitem.addEventListener('mouseover', function (event) {
            const el = event.currentTarget.querySelector('.dropdown-menu');
            if(el) {
                el.classList.remove('show-on-left');
                var rect = el.getBoundingClientRect();
                if(rect.x + rect.width > window.innerWidth) {
                    el.classList.add('show-on-left');
                }
            }
        });
    });

    const setMobileNavPosition = () => {
        const headerHeight = document.querySelector('.header').clientHeight;
        document.querySelector('.navbar-nav').style.top = headerHeight+'px';
        document.querySelector('.navbar-nav').style.height = document.documentElement.scrollHeight - headerHeight + "px";
    }
    setMobileNavPosition();
    window.addEventListener('resize', setMobileNavPosition);

    document.querySelectorAll('.mobile-subnav-trigger').forEach( (trigger) => {
        trigger.addEventListener('click', function (event) {
            const item = trigger.parentElement;
            const dropdown = item.querySelector('.dropdown-menu');

            console.log(item, item.parentNode, item.parentNode.children);
            const siblings = [...item.parentNode.children].filter((child) =>
                child !== item
            );
            

            siblings.forEach((sibling) => {
                if(sibling.querySelector('.dropdown-menu')) {
                    sibling.querySelectorAll('.dropdown-menu').forEach(dropdown => {
                        dropdown.style.display = 'none';
                    })
                    sibling.querySelectorAll('.mobile-subnav-trigger i').forEach(subnavTrigger => {
                        subnavTrigger.classList.remove('rotated');
                    });    
                }
            })

            event.currentTarget.querySelector('i').classList.toggle('rotated');
            if(dropdown) {
                if (window.getComputedStyle(dropdown).display === 'block') {
                    dropdown.style.display = 'none';
                    return;
                }
                dropdown.style.display = 'block';
            }
        });
    })


}
export default HeaderScripts;