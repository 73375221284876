const MembersListShowMore = () => {
    document.querySelectorAll('.members-list > div').forEach((item,index) => {
        
        const itemLink = item.querySelector('.moreLink a');
        if(itemLink) {
            itemLink.classList.add('collapsed');
            item.dataset.order = index;

            itemLink.addEventListener('click',  event => {
                const target = event.currentTarget;
                const mylist = target.closest('.members-list');
                const outerParent = target.closest('div[data-order]');
                
                const resetMemberDetails = (el) => {
                    el.querySelector('.member-summary').setAttribute('hidden', false);
                    el.querySelector('.member-details').setAttribute('hidden', true);
                    el.querySelector('.moreLink a').innerText = 'Show more';
                    el.querySelector('.moreLink a').classList.add('collapsed');
                }

                [...mylist.children].forEach( item => {
                    if(
                        item.getAttribute('data-order') !== outerParent.getAttribute('data-order') 
                        && item.tagName === "DIV" 
                        && item.querySelector('.moreLink')
                    ) 
                    {
                        resetMemberDetails(item);
                    }
                });

                if(target.classList.contains('collapsed')) {
                    outerParent.querySelector('.member-summary').setAttribute('hidden', true);
                    outerParent.querySelector('.member-details').setAttribute('hidden', false);
                    target.innerText = 'Show less';
                    target.classList.remove('collapsed');
                }
                else {
                    resetMemberDetails(outerParent);
                }
            
            });
        }    
    });
}
export default MembersListShowMore;