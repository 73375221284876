const CookiePolicy = () => {
    const hours = 4383 // half year; 
    const now = Date.now();
    const setupTime = localStorage.getItem('setupTime');

    if (now - setupTime > hours*60*60*1000) {
        localStorage.clear();
    }

    if (localStorage.getItem('cookies_enabled') === null) {
        document.getElementById('cookie-consent').style.display = 'block';
    }

    if(document.getElementById('cookie-aprove') !== null){
        document.querySelector('#cookie-aprove').addEventListener('click', function (event) {
            document.getElementById('cookie-consent').remove();
            localStorage.setItem('cookies_enabled', '1');
            localStorage.setItem('setupTime', now);
        });
    }
}
export default CookiePolicy;