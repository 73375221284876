const Forms = () => {
    const togglePassword = document.getElementById('password-reveal');
    const password = document.getElementById('password');

    if(togglePassword) {
        togglePassword.addEventListener("click", function () {
            const type = password.getAttribute("type") === "password" ? "text" : "password";
            password.setAttribute("type", type);
            
            this.classList.toggle("icon-eye");
            this.classList.toggle("icon-eye-blocked");
        });
    }        
}
export default Forms;